@import "../../mixins";

#agenda > article {

    .agenda-search-cont {
        margin-bottom: 10px;
        .agenda_search {
            width: 100%;
            border: 1px solid rgba(0,0,0,0.15);
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .agendadocs {
        text-align: center;
        .agendadoc {
            cursor: pointer;
            padding: 10pt 0;
            font-size: 12pt;
        }
    }

    #agendaListTab {
        align-items: flex-start;

        .agenda_channel_tab {
            .agenda_channel_title {
                text-align: center;
                padding: 5pt;
                background-color: #aaaaaa;
                color: #fff;
            }

        }
    }

    #agendaList, #agendaListTab {
        .item {

            .favorite_agenda {
                padding: 10px 0;
            }

            //order: 1;
            position: relative;
            .info {
                padding: 0 dp(12);
            }

            .chevron {
                padding: 0 dp(4);
            }

            .now,
            .expired {
                display: none;
            }

            .header {
                font-size: 95%;
                // .now {
                //   text-transform: uppercase;
                // }
                .rooms {
                    margin-left: dp(16);
                }
            }

            &.now {
                background-color: #333;
                color: #fff;
                border-color: #444;
                margin: 0 -2vmax;
                @media screen and (min-width: 780px) {
                    margin: 0 -2vmax;
                }
                //background-color: #f9f9f9;
                //order: 0;
                .text-secondary {
                    //color: #555;
                    color: inherit;
                }

                .date {
                    display: none;
                }

                .now {
                    display: block;
                }
            }

            &.ended {
                //color: #999;
                /*.text-secondary {
                  color: inherit;
                }*/
                .date {
                    display: none;
                }

                .expired {
                    display: block;
                }
            }

            section {
                display: block;
            }

            &.breaking {
                pointer-events: none;

                .chevron,
                .star {
                    color: transparent;
                }
            }

            .title-short {
                font-size: 140%;
            }

            .title-long {
                font-size: 120%;
            }

            .title-extra {
                font-size: 110%;
            }
        }
    }

    .date-wrapper-tab {
        padding: 0;
        text-align: center;
        align-items: stretch;

        .agenda_day_tab {
            border: 1px solid rgba(0,0,0,.15);
            border-radius: 5pt 5pt 0 0;
            padding: 10pt;
            cursor: pointer;
            &.active {
                color: #ffffff;
            }
        }
    }

    .date-wrapper {
        display: block;
        position: relative;
        padding: 0;
        .fstdiv {
            height: 40px !important;
            .fstdropdown {
                border: none !important;
                background: transparent !important;

                &.open {
                    &:hover {
                        box-shadow: none !important;
                        border-color: transparent !important;
                    }
                }

                .fstselected {
                    text-align: center !important;
                    font-size: 14pt !important;
                    font-weight: bold;
                    box-shadow: none !important;

                    &:after {
                        border-color: #000 transparent transparent;
                        border-width: 8px 6px 0;
                    }
                }

                .fstlist {
                    //border: 1px solid #cecece;
                    > div {
                        color: #fff !important;
                        padding: 10px !important;
                        border-radius: 5px !important;
                        border-bottom: 2px solid rgba(255, 255, 255, .95);

                        &.selected {
                            background-color: transparent;
                        }

                        &:hover {
                            background-color: transparent;
                            color: inherit;
                        }
                    }
                }
            }
        }

        .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-color: white;
            font-size: 120%;
            font-weight: bold;
            padding: 0 dp(16);

            .content {
                text-align: center;
            }
        }

        @media (pointer: fine) {
            &:hover .mask {
                background-color: #f9f9f9;
            }
        }

        &:active .mask {
            background-color: #f9f9f9;
        }
    }

    .date-selector {
        -webkit-appearance: none;
        width: 100%;
        border: none;
        cursor: pointer;
        padding: dp(16) 0;
    }
}
