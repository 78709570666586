@import "../../mixins";

#sponsors > article {
    .item {
        cursor: pointer;
    }

    .sponsor_map {
        margin-top: 10pt;
        #logok {
            image {
                cursor: pointer;
                &.nopointer {
                    pointer-events: none;
                }
            }
            use {
                cursor: pointer;
            }
        }
    }

    .sponsorsList {
        .sponsors_cont {
            .link {
                padding: 10pt;
                cursor: pointer;
                img {
                    max-width: 100%;
                    max-height: 100px;
                    padding: 20px 0;
                    &.increased {
                        max-height: 220px;
                    }
                }
            }
        }
    }

    .grouptitle {
        text-align: center;
        margin-top: 15pt;
        &:first-child {
            margin-top: 3pt;
        }
    }

    .cover {
        width: 100%;
        max-width: 300px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        text-align: center;
        border-radius: 5px;
        font-size: 30px;
        z-index: 3000;
    }
    .whitebg {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
    }


    .search-wrapper {
        padding: 0;
        position: relative;
        cursor: text;

        .icon-before {
            padding: dp(16);
        }

        input {
            width: 100%;
            padding: dp(16) dp(32) dp(16) 0;
            margin: 0;
            border: 0;
        }

        .btn-clear {
            display: none;
            position: absolute;
            right: 0;
            padding: dp(16);
            cursor: pointer;
        }
    }

    .no-result {
        display: none;
        margin-top: dp(32);
        text-align: center;
        font-size: 110%;
    }
}
